import React, { useState } from 'react';
import styled from '@emotion/styled';

function InputAnswer(props) {
  const [value, setValue] = useState("");
  const handleSubmit = (evt) => {
      evt.preventDefault();
  }

  //console.log(props.question.id);
  return (
    <form onSubmit={handleSubmit}>
      <InputAnswerBox
        type="text"
        value={value}
        onChange={e => setValue(e.target.value)}
      />
      <br/>
      <Submit type="submit" value={value} onClick={props.handleClick}>SUBMIT</Submit>
    </form>
  );
}

export default InputAnswer;

const InputAnswerBox = styled.input`
  background: tranparent;
  color: #03c;
  border: 2px solid #03c;
  padding: 1rem .5rem;
  width: 40vmax;
  height: 5rem;
  border-radius: none;
  text-align: left;
  margin: 0;
  position: relative;
  font-size: 28px;
  transition:
    color .5s ease-in-out,
    background-color .5s ease-in-out,
    height 1s ease-in-out,
    border 3s ease-in-out
  ;
  &:focus {
    outline: none;
    border: 2px solid #cc004b;
  }
`;

const Submit = styled.button`
  background: #03c;
  color: #fff;
  border: none;
  padding: 1rem .5rem;
  width: 20vmax;
  height: 5rem;
  border-radius: none;
  text-align: center;
  margin: 2rem 0 0 0;
  position: relative;
  font-size: 20px;
  transition: background .5s linear;
  &:focus {
    outline: none;
    background: #cc004b;
  }
  &:hover {
    outline: none;
    background: #cc004b;
    cursor: pointer;
    transition: background .3s linear;
  }
`;
