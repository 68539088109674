import React from 'react'
import styled from '@emotion/styled'

function Progress(props) {

  const progWidth = (props.current / props.total * 100).toString() + "%";

  return (
      <OuterBar>
        <InnerBar style={{width: `${progWidth}`}}>
          {/* Question {props.current} of {props.total} */}
        </InnerBar>
      </OuterBar>
  );
}

export default Progress;

const OuterBar = styled.div`
  width: 100%;
  height: 4px;
  background: transparent;
`

const InnerBar = styled.div`
  height: 4px;
  background: #03c;
  transition: ease-in-out, all 1s ease-in-out;
`
