import React from 'react'
import styled from '@emotion/styled'

function Answer(props) {
  let classes = ['answer']

  if (props.selected) {
    classes.push('selected')
  }

  return (
    <Button
      value={props.answer}
      className={props.className + ' ' + classes.join(' ')}
      onClick={props.handleClick}
      clickDiff={props.clickDiff}
    >
      {props.answer}
    </Button>
  );
}

export default Answer;

const Button = styled.button`
  color: #4f4c4c;
  background-color: #f4f4f4;
  padding: 10px 50px;
  text-transform: uppercase;
  font-size: 18px;
  margin-top: 20px;
  cursor: pointer;
`;
