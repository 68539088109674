import React from 'react';
import Particles from 'react-particles-js';

function CmpParticles(props) {

  return (
    <Particles
      params={{
        fpsLimit: 60,
        interactivity: {
          detectsOn: "window",
          events: {
            onClick: {
              enable: props.current > 4 ? true : false,
              mode: "connect"
            },
            onHover: {
              enable: props.current === 8
                      ? false
                      : props.current > 4
                      ? true
                      : false,
              mode: "repulse"
            },
            resize: true
          },
          modes: {
            connect: {
              distance: 600,
              radius: (300 / 2),
            },
            repulse: {
              distance: 300,
              duration: .4,
              speed: .4,
            }
          }
        },
        particles: {
          number: {
            value: props.clicks,
            density: {
              enable: false,
              value_area: 1000,
            }
          },
          color: {
            value:
              props.current > 5 ? "hsl(" + (props.randClr + (props.current * 20)) + ", 74%, 46%)"
            : props.current > 4 ? "hsl(" + props.randClr + ", 74%, 46%)"
            : props.current > 2 ? "#fff"
            : "#03c",
          },
          opacity: {
            anim: {
              enable: props.current > 2 ? false : true,
              value	:	.8,
            },
            value	:	.8,
          },
          size: {
            value: props.answersArr[props.prevAnswerNo].answer === "AGREE" ? 10 : 8,
            random: false,
            anim: {
              enable: false,
            },
          },
          move: {
            enable: props.current > 18
                  ? false
                  : props.current === 7
                  ? false
                  : props.current === 8
                  ? true
                  : props.answersArr[props.prevAnswerNo].answer === "DISAGREE"
                  ? true
                  : false,
            speed: props.current === 8
                   ? .1
                   : 11 - (props.seconds > 10 ? 10 : props.seconds < 3 ? 1 : props.seconds),
            direction: "none",
            random: false,
            straight: false,
            bounce: false,

          },
          line_linked: {
            enable: false,
          }
        },
        detectRetina: true

      }}
    />
  );
}

export default CmpParticles;
