import React from 'react';
import Typist from 'react-typist';
import styled from '@emotion/styled';
import 'react-typist/dist/Typist.css';
//import QuizContext from '../context/QuizContext';

function Question(props) {
    // const {state} = useContext(QuizContext);
    // const {currentQuestion, questions} = state;
    // const question = questions[currentQuestion];
    return (
      <Div className={props.className}>
      <h1>
        <Typist
          key={props.question}
          startDelay={750}
          cursor={{
            show: true,
            blink: true,
            element: '▎',
            hideWhenDone: false,
            hideWhenDoneDelay: 600,
          }}
        >
          {props.question}
        </Typist>
      </h1>
      </Div>
    );
}

export default Question

const Div = styled.div`
  > h1 {
    color: #03c;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;
