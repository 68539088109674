export const questions = [
  {
      id: 1,
      question: 'You find yourself using a computer or smartphone more than once every hour',
      answer_a: 'AGREE',
      answer_b: 'DISAGREE',

      correct_answer: 'b',
  },
  {
      id: 2,
      question: 'Are you someone who sets times in the day to do particular tasks?',
      answer_a: 'YES',
      answer_b: 'NO',

      correct_answer: 'b',
  },
  {
      id: 3,
      question: 'You need to improve your attention span',
      answer_a: 'AGREE',
      answer_b: 'DISAGREE',

      correct_answer: 'b',
  },
  {
      id: 4,
      question: 'Describe your current mood in one word',
      answer_a: 'AGREE',

      answer_input: 'b',
  },
  {
      id: 5,
      question: 'You actually take regular breaks in your working day',
      answer_a: 'AGREE',
      answer_b: 'DISAGREE',

      correct_answer: 'b',
  },
  {
      id: 6,
      question: 'Do you prefer quantity over quality?',
      answer_a: 'YES',
      answer_b: 'NO',

      correct_answer: 'b',
  },
  {
      id: 7,
      question: 'You like to sketch things out',
      answer_a: 'AGREE',
      answer_b: 'DISAGREE',

      correct_answer: 'b',
  },
  {
      id: 8,
      question: 'Describe what you see on this page',
      answer_input: 'AGREE',

  },
  {
      id: 9,
      question: "... hmmm that's interesting!",
      answer_a: 'AGREE',
      answer_b: 'DISAGREE',

      correct_answer: 'b',
  },
  {
      id: 10,
      question: 'Are you someone who actually enjoys repetitive tasks?',
      answer_a: 'YES',
      answer_b: 'NO',

      correct_answer: 'b',
  },
  {
      id: 11,
      question: 'You can walk and text at the same time',
      answer_a: 'AGREE',
      answer_b: 'DISAGREE',

      correct_answer: 'b',
  },
  {
      id: 12,
      question: 'Is this your first online quiz?',
      answer_a: 'YES',
      answer_b: 'NO',

      correct_answer: 'b',
  },
  {
      id: 13,
      question: 'You take your phone to bed with you',
      answer_a: 'AGREE',
      answer_b: 'DISAGREE',

      correct_answer: 'b',
  },
  {
      id: 14,
      question: 'You do not look at your phone if you wake up in the night',
      answer_a: 'AGREE',
      answer_b: 'DISAGREE',

      correct_answer: 'b',
  },

  {
      id: 15,
      question: 'You are easily distracted by nature',
      answer_a: 'AGREE',
      answer_b: 'DISAGREE',

      correct_answer: 'b',
  },
  {
      id: 16,
      question: 'Do you keep lots of tabs open at once?',
      answer_a: 'YES',
      answer_b: 'NO',

      correct_answer: 'b',
  },
  {
      id: 17,
      question: 'You think questions have one right answer',
      answer_a: 'AGREE',
      answer_b: 'DISAGREE',

      correct_answer: 'b',
  },
  {
      id: 18,
      question: 'You follow recipes',
      answer_a: 'AGREE',
      answer_b: 'DISAGREE',

      correct_answer: 'b',
  },
  {
      id: 19,
      question: 'Draw something from your current surroundings',
      answer_a: 'Submit',

      correct_answer: 'a',
  },
  {
      id: 20,
      question: 'Now give your drawing a title',
      answer_input: 'AGREE',

      correct_answer: 'b',
  },
  {
      id: 21,
      question: 'Are you stressed out by mess?',
      answer_a: 'YES',
      answer_b: 'NO',

      correct_answer: 'b',
  },
  {
      id: 22,
      question: 'You read the terms and conditions',
      answer_a: 'YES',
      answer_b: 'NO',

      correct_answer: 'b',
  },

];
