import React, { useState, useEffect, useRef } from 'react';
import { questions } from "./data/questions.js";
import globalStyles from "./components/styles/global-styles";
import styled from '@emotion/styled';
import { Global } from '@emotion/core';
import Progress from './components/Progress';
import Question from './components/Question';
import Answers from './components/Answers';

//
import html2canvas from 'html2canvas';
import InputAnswer from './components/InputAnswer';
import CmpParticles from './components/backgrounds/Particles';
import Canvas from './components/canvas';
import CmpLines from './components/backgrounds/Lines';
import Consent from './components/terms-cookies';
import BlurCorner from './components/blurCorner';

import ImageFadeIn from "react-image-fade-in";
import muteBtn from './media/audio-button.svg';
import agreeS from './media/Agree.mp3';
import disagreeS from './media/Disagree.mp3';
import sx1 from './media/Section-1.mp3';
import sx12 from './media/Section-1-2.mp3';
import sx123 from './media/Section-1-2-3.mp3';
//import SwitchFunc from './components/SwitchFunc';
import useWindowDimensions from './components/WindowDimensions';
import useMouse from '@react-hook/mouse-position';
import {isMobile} from 'react-device-detect';
//
import * as Fathom from 'fathom-client';

import './App.css';

const App = () => {
  // Initialize Fathom when the app loads
  useEffect(() => {
   Fathom.load('ELQMNIYU', {
     excludedDomains: ['localhost']
   });
 }, []);

  //mouse x y

  const ref = useRef(null);
  const mouse = useMouse(ref, {
    enterDelay: 100,
    leaveDelay: 100,
  });
  //const [mouseXp, setMouseXp] = useState(0);
  const agreeRef = useRef(null);
  const agree2Ref = useRef(null);
  const disagreeRef = useRef(null);
  const disagree2Ref = useRef(null);
  const sx1Ref = useRef(null);
  const sx12Ref = useRef(null);
  const sx123Ref = useRef(null);
  //
  // window dimensions
  const { height, width } = useWindowDimensions();
  const [logWH, setLogWH] = useState([[width, height, new Date().getTime()]]);
  const [logMouseXY, setLogMouseXY] = useState([[0, 0, new Date().getTime()]]);
  const [tlCount, setTlCount] = useState(0);
  const [trCount, setTrCount] = useState(0);
  const [blCount, setBlCount] = useState(0);
  const [brCount, setBrCount] = useState(0);

  useInterval(() => {
    if (logMouseXY[logMouseXY.length - 1][0] !== mouse.x) {
      if (logWH[logWH.length - 1][0] !== width || logWH[logWH.length - 1][1] !== height) {

        setLogWH([...logWH, [width, height, new Date().getTime()]]);
      }
      if (mouse.x !== null){
        setLogMouseXY([...logMouseXY, [mouse.x, mouse.y, new Date().getTime()]]);
        if (mouse.x < (width / 2) && mouse.y < (height / 2)) {
          setTlCount(tlCount + 1);
        } else if (mouse.x < width && mouse.y < (height / 2)) {
          setTrCount(trCount + 1);
        } else if (mouse.x < (width / 2) && mouse.y < height) {
          setBlCount(blCount + 1);
        } else if (mouse.x < width && mouse.y < height) {
          setBrCount(brCount + 1);
        }
      }
    }
  }, 6000);
  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
  //


  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [answers, setAnswers] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [showResults2, setShowResults2] = useState(false);
  const [showResults3, setShowResults3] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [error, setError] = useState('');

  //timer
  const [times, setTimes] = useState([Math.floor( new Date().getTime() / 1000 )]);
  //
  //clicks
  const [clickCount, setClickCount] = useState(0);
  const [clickCountUpdate, setClickCountUpdate] = useState(0);
  //
  const [randClr, setRandClr] = useState(430 - (new Date().getUTCHours() * 15));
  const question = questions[currentQuestion];


  const handleClick = e => {
    Fathom.trackGoal('V7HTEYC5', 0);
    setCurrentAnswer(e.target.value);

    if (e.target.value === 'DISAGREE' || e.target.value === 'NO'){
      disagreeRef.current.play();
    } else if (e.target.value) {
      agreeRef.current.play();
    }
    setError('');
  };
  useEffect(() => {
    if (currentAnswer !== ''){
      const timer = setTimeout(() => next(), 3000);
      return () => clearTimeout(timer);
    }
  }, [currentAnswer]);

  const handleInputClick = e => {
    setCurrentAnswer(e.target.value);
    if (e.target.value) {
      agreeRef.current.play();
    } else {
      disagreeRef.current.play();
    }
    setError('');
  };

  const renderError = () => {
    if(!error){
      return;
    }
    return <Error>{error}</Error>;
  };



  const renderResultsData = () => {
    return answers.map(answer => {
      const question = questions.find(
        question => question.id === answer.questionId
      );

      return (
        <div key={answer.uKey}>
          {answer.uKey} - {('0'+new Date(answer.uKey).getUTCHours()).slice(-2)} : {('0'+new Date(answer.uKey).getMinutes()).slice(-2)} : {('0'+new Date(answer.uKey).getSeconds()).slice(-2)} - {question.question} - {answer.answer}
        </div>
      );
    });
  };

  const mapTimes = () => {
    return times.map((time, i) => {
      return (
        <div key={time}>
          {time} - {('0'+new Date((time * 1000)).getUTCHours()).slice(-2)} : {('0'+new Date((time * 1000)).getMinutes()).slice(-2)} : {('0'+new Date((time * 1000)).getSeconds()).slice(-2)} - {times[i] - times[i - 1]}s
        </div>
      );
    });
  };

  const mapXY = () => {
    return logMouseXY.map(xy => {
      return (
        <div key={xy[2]}>
          {xy[2]} - {('0'+new Date(xy[2]).getUTCHours()).slice(-2)} : {('0'+new Date(xy[2]).getMinutes()).slice(-2)} : {('0'+new Date(xy[2]).getSeconds()).slice(-2)} - x: {xy[0]} - y: {xy[1]}
        </div>
      );
    });
  };

  const mapWH = () => {
    return logWH.map(wh => {
      return (
        <div key={wh[2]}>
          {wh[2]} - {('0'+new Date(wh[2]).getUTCHours()).slice(-2)} : {('0'+new Date(wh[2]).getMinutes()).slice(-2)} : {('0'+new Date(wh[2]).getSeconds()).slice(-2)} - width: {wh[0]} - height: {wh[1]}
        </div>
      );
    });
  };

  const restart = () => {
    setAnswers([]);
    setCurrentAnswer('');
    setCurrentQuestion(0);
    setShowResults(false);
    setShowResults2(false);
    setShowResults3(false);
    setClickCount(0);
  };

  const next = () => {
  //tracking goals
  if (currentQuestion === 0) {
    Fathom.trackGoal('KHCCZZNR', 0);
  } else if (currentQuestion === 1) {
    Fathom.trackGoal('VYUP2TYB', 0);
  } else if (currentQuestion === 2) {
    Fathom.trackGoal('XMSWXSFN', 0);
  } else if (currentQuestion === 3) {
    Fathom.trackGoal('9AU2WCNN', 0);
  } else if (currentQuestion === 4) {
    Fathom.trackGoal('EURNEK2O', 0);
  } else if (currentQuestion === 5) {
    Fathom.trackGoal('BD6KPLNL', 0);
  } else if (currentQuestion === 6) {
    Fathom.trackGoal('JFHGALAJ', 0);
  } else if (currentQuestion === 7) {
    Fathom.trackGoal('SRIJZ5OS', 0);
  } else if (currentQuestion === 8) {
    Fathom.trackGoal('J9XL0XK9', 0);
  } else if (currentQuestion === 9) {
    Fathom.trackGoal('KZ30PZUR', 0);
  } else if (currentQuestion === 10) {
    Fathom.trackGoal('DSKXNGYC', 0);
  } else if (currentQuestion === 11) {
    Fathom.trackGoal('LGZWWUTJ', 0);
  } else if (currentQuestion === 12) {
    Fathom.trackGoal('GOCTD0OP', 0);
  } else if (currentQuestion === 13) {
    Fathom.trackGoal('FQBNULIU', 0);
  } else if (currentQuestion === 14) {
    Fathom.trackGoal('JOCUBICY', 0);
  } else if (currentQuestion === 15) {
    Fathom.trackGoal('OBPPLY46', 0);
  } else if (currentQuestion === 16) {
    Fathom.trackGoal('C8TXBOE7', 0);
  } else if (currentQuestion === 17) {
    Fathom.trackGoal('MYJCYNQ2', 0);
  } else if (currentQuestion === 18) {
    Fathom.trackGoal('EK8G8UWK', 0);
  } else if (currentQuestion === 19) {
    Fathom.trackGoal('PDFUJVSS', 0);
  } else if (currentQuestion === 20) {
    Fathom.trackGoal('Z7BEFRCL', 0);
  } else if (currentQuestion === 21) {
    Fathom.trackGoal('QGTRQOPD', 0);
    if (Math.max(tlCount, trCount, blCount, brCount) === tlCount) {Fathom.trackGoal('DPV79YFX', 0)}
    else if (Math.max(tlCount, trCount, blCount, brCount) === trCount) {Fathom.trackGoal('5FM8TJFS', 0)}
    else if (Math.max(tlCount, trCount, blCount, brCount) === blCount) {Fathom.trackGoal('EX5J9RXT', 0)}
    else if (Math.max(tlCount, trCount, blCount, brCount) === brCount) {Fathom.trackGoal('NMVDERVG', 0)}
  }

    setClickCountUpdate(clickCount);
    setTimes([...times, Math.floor( new Date().getTime() / 1000 )]);

    const answer = {questionId:question.id, answer: currentAnswer, uKey: new Date().getTime()};
    //console.log(currentQuestion);
    if (currentQuestion === 0) {
      sx1Ref.current.play();
      aud_fade0();
    }
    // if (currentQuestion === 4) {
    //   aud_fade1a();
    // }
    if (currentQuestion === 5) {
      sx12Ref.current.play();
      aud_fade1();
      aud_fade1a();
    }
    // if (currentQuestion === 12) {
    //   aud_fade2a();
    // }
    if (currentQuestion === 13) {
      sx123Ref.current.play();
      aud_fade2();
      aud_fade2a();
    }
    if (currentAnswer){
      //agree2Ref.current.play();
    } else if (!currentAnswer){
      if (currentQuestion === 6 ) {
        setError('(┛◉Д◉)┛彡┻━┻\nPlease complete the task and then select an answer');
        alert('(┛◉Д◉)┛彡┻━┻\nPlease complete the task and then select an answer');
        disagree2Ref.current.play();
      } else if (currentQuestion === 18 ) {
        setError('(┛◉Д◉)┛彡┻━┻\nPlease complete the task');
        alert('(┛◉Д◉)┛彡┻━┻\nPlease complete the task');
        disagree2Ref.current.play();
      } else if (currentQuestion === 3 || currentQuestion === 7 || currentQuestion === 19 ){
        setError('꒰ ꒡⌓꒡꒱\nPlease submit an answer');
        disagree2Ref.current.play();
      } else {
        setError('꒰ ꒡⌓꒡꒱\nPlease select an answer');
        disagree2Ref.current.play();
      }
      return;
    }
    setAnswers([...answers, answer]);
    setCurrentAnswer('');

    if (currentQuestion + 1 < questions.length){
      setCurrentQuestion(currentQuestion + 1);

      return;
    }

    setShowResults(true);
  };

  const prev = () => {
    //const answer = { questionId:question.id, answer: currentAnswer }

    if (currentQuestion > 0){
      setCurrentQuestion(currentQuestion - 1);

      return;
    }
  };

  const test = () => {
    Fathom.trackGoal('LYR3E2PI', 0);
    html2canvas(ref.current)
     .then(function (canvas) {

        var a = document.createElement('a');
        a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
        a.download = 'are-you-actually-compiler-results.jpg';
        a.click();
     });
  }
  const aud_fade0 = () => {
    if (sx1Ref.current.volume < 0.995) {
      sx1Ref.current.volume += 0.005;
      setTimeout(aud_fade0,50);
    } else {
      sx1Ref.current.volume = 1;
    }
  }
  const aud_fade1 = () => {
    if (sx1Ref.current.volume > 0.005) {
      sx1Ref.current.volume -= 0.005;
      setTimeout(aud_fade1,100);
    } else {
      sx1Ref.current.volume = 0;
      sx1Ref.current.pause();
    }
    // if (sx12Ref.current.volume < 0.995) {
    //   sx12Ref.current.volume += 0.005;
    //   setTimeout(aud_fade1,100);
    // } else {
    //   sx12Ref.current.volume = 1;
    // }
  }
  const aud_fade1a = () => {
    // if (sx1Ref.current.volume < 0.995) {
    //   sx1Ref.current.volume += 0.005;
    //   setTimeout(aud_fade1a,100);
    // } else {
    //   sx1Ref.current.volume = 1;
    // }
    // if (sx12Ref.current.volume > 0.005) {
    //   sx12Ref.current.volume -= 0.005;
    //   setTimeout(aud_fade1a,100);
    // } else {
    //   sx12Ref.current.volume = 0;
    // }
    if (sx12Ref.current.volume < 0.995) {
      sx12Ref.current.volume += 0.005;
      setTimeout(aud_fade1a,100);
    } else {
      sx12Ref.current.volume = 1;
    }
  }
  const aud_fade2 = () => {
    if (sx12Ref.current.volume > 0.005) {
      sx12Ref.current.volume -= 0.005;
      setTimeout(aud_fade2,100);
    } else {
      sx12Ref.current.volume = 0;
      sx12Ref.current.pause();
    }
    // if (sx123Ref.current.volume < 0.995) {
    //   sx123Ref.current.volume += 0.005;
    //   setTimeout(aud_fade2,100);
    // } else {
    //   sx123Ref.current.volume = 1;
    // }
  }
  const aud_fade2a = () => {
    // if (sx12Ref.current.volume < 0.995) {
    //   sx12Ref.current.volume += 0.005;
    //   setTimeout(aud_fade2a,5);
    // } else {
    //   sx12Ref.current.volume = 1;
    // }
    // if (sx123Ref.current.volume > 0.005) {
    //   sx123Ref.current.volume -= 0.005;
    //   setTimeout(aud_fade2a,5);
    // } else {
    //   sx123Ref.current.volume = 0;
    // }
    if (sx123Ref.current.volume < 0.995) {
      sx123Ref.current.volume += 0.005;
      setTimeout(aud_fade2a,100);
    } else {
      sx123Ref.current.volume = 1;
    }
  }
  //console.log(isMuted);

    return (
      <>
      <Background
        ref={ref}
        onMouseUp={() => {!isMobile && setClickCount(clickCount + 1);}}
        onTouchEnd={() => {setClickCount(clickCount + 1);}}
      >
      <MuteBtn
        src={muteBtn}
        onClick={() => {
            agreeRef.current.muted = !agreeRef.current.muted;
            agree2Ref.current.muted = !agree2Ref.current.muted;
            disagreeRef.current.muted = !disagreeRef.current.muted;
            disagree2Ref.current.muted = !disagree2Ref.current.muted;
            sx1Ref.current.muted = !sx1Ref.current.muted;
            sx12Ref.current.muted = !sx12Ref.current.muted;
            sx123Ref.current.muted = !sx123Ref.current.muted;
            setIsMuted(!isMuted);
          }}
        toggle={isMuted}
      />
      <audio ref={agreeRef} src={agreeS} />
      <audio ref={agree2Ref} src={agreeS} />
      <audio ref={disagreeRef} src={disagreeS} />
      <audio ref={disagree2Ref} src={disagreeS} />
      <audio ref={sx1Ref} src={sx1} loop volume="0" />
      <audio ref={sx12Ref} src={sx12} loop volume="0" />
      <audio ref={sx123Ref} src={sx123} loop volume="0" />
        <Global styles={globalStyles} />
        <Container>
          <Progress
            total={questions.length}
            current={currentQuestion + 1}
          />
          {currentQuestion === 8 &&
            <PrevAnswer currentQuestion={currentQuestion}>{answers[answers.length - 1].answer + " "} </PrevAnswer>
          }
          <Inner currentQuestion={currentQuestion}>
          {currentQuestion > 5 &&

              <Canvas
                prevAnswerNo={answers.length - 1}
                clicks={clickCountUpdate}
                currentQuestion={currentQuestion}
              />

          }
          {!showResults &&
            <StyledQuestion
              currentQuestion={currentQuestion}
              question={question.question}
              currentAnswer={currentAnswer}
            />
          }
          {(currentQuestion === 3 || currentQuestion === 7 || currentQuestion === 19) ?
            !showResults &&
            <InputAnswer

              handleClick={handleInputClick}
            />
            : !showResults &&
            <Answers
              question={question}
              currentAnswer={currentAnswer}
              handleClick={handleClick}
              clickDiff={clickCount - clickCountUpdate}
            />
          }
          </Inner>

          {renderError()}
          {/*!showResults &&
            <Continue
              current={currentQuestion + 1}
              currentAnswer={currentAnswer}
              onClick={() => {next();}}
            >
              Continue &gt;
            </Continue>*/}

          {currentQuestion > 0 && !showResults &&
            <Back onClick={prev}>
              &lt; Back
            </Back>
          }
        </Container>

        <InnerBg
          currentQuestion={currentQuestion}
          currentAnswer={currentAnswer}
        >

          <InnerFakeBg
            currentQuestion={currentQuestion}
            clickCount={clickCount}

            mouseXp={Math.round(mouse.x / width * 100)}
            mouseYp={Math.round(mouse.y / height * 100)}
          />
          {
            currentQuestion > 0 &&
            <CmpParticles
              current={currentQuestion + 1}
              prevAnswerNo={answers.length - 1}
              answersArr={answers}
              seconds={times[answers.length] - times[answers.length - 1]}
              clicks={clickCountUpdate}
              randClr={randClr}
            />}
            {
          currentQuestion > 0 &&
            <CmpLines
              current={currentQuestion + 1}
              startSecs={times[0]}
              seconds={times[currentQuestion] - times[currentQuestion - 1]}
              times={times}
              prevAnswerNo={answers.length - 1}
              answersArr={answers}
            />
          }

            <BlurCorner corner={Math.max(tlCount, trCount, blCount, brCount) === tlCount
                          ? 0 : Math.max(tlCount, trCount, blCount, brCount) === trCount
                          ? 1 : Math.max(tlCount, trCount, blCount, brCount) === blCount
                          ? 2 : Math.max(tlCount, trCount, blCount, brCount) === brCount
                          ? 3 : 1} />

        </InnerBg>
        {showResults & !showResults2 &&
            <Results results="true">
              <div style={{width: '100%', maxWidth: '720px', margin: '0 auto', textAlign: 'center', fontSize: '16px', color: '#03c', textShadow: '0px 0px 4px rgba(255, 255, 255, 1)'}}>
              <h2 style={{fontSize: '24px'}}>Are / You / Actually Quiz complete!</h2></div>
              <div style={{width: '100%', maxWidth: '720px', margin: '0 auto', textAlign: 'left', fontSize: '16px'}}>
              <p></p>
              <p>Oh... were you hoping to be given a category or results?</p>
              <p>You do not pass or fail this quiz. Instead, Are You Actually shows how much data you create through even simple interactions with websites.</p>
              <p>For this quiz, your data is used to generate the visuals,
              e.g. the dots in the background represent the number of times you have clicked or tapped on the screen while completing the quiz.
              However, this process is often part of something bigger. The tracking, sorting and selling of data is called Surveillance Capitalism.</p>
              <p>On the next page, you will be able to see your data from this quiz. </p>
              <p>This data is still live and updating! So scroll down, use your mouse or touchscreen to find which interactions are still being logged.</p>
              <p>Once you have finished interacting, download a screenshot of your results for future reference! </p>
              <p>Share your screenshot with us on social media @comilerzone & @PhoenixLeic </p></div>
            </Results>
          }
          {showResults2 & !showResults3 &&
          <Results>
            <div>time and date completed: {new Date().toUTCString()}</div>
            <div>current window height: {height}</div>
            <div>current window width: {width}</div>
            <ul>
              <div>window width/height history: </div>
              {mapWH()}
            </ul>
            <ul>
              <div>mouse / touch xy position history: </div>
              {mapXY()}
            </ul>
            <ul>
              <div>% of time active in each quarter of screen: </div>
              <div>
                top left: {Math.round((tlCount / (tlCount + trCount + blCount + brCount)) * 10000)/100}%<br/>
                top right: {Math.round((trCount / (tlCount + trCount + blCount + brCount)) * 10000)/100}%<br/>
                bottom left: {Math.round((blCount / (tlCount + trCount + blCount + brCount)) * 10000)/100}%<br/>
                bottom right: {Math.round((brCount / (tlCount + trCount + blCount + brCount)) * 10000)/100}%
              </div>
            </ul>
            <ul>
              <div>answer times + intervals:</div>
              {mapTimes()}
            </ul>
            <div>root hue: {randClr}</div>
            <div>click count: {clickCount}</div>
            <div>prev click count: {clickCountUpdate}</div>
            <div>total time (in seconds): {times[times.length - 1] - times[0]}</div>
            <ul>
              <div>answers:</div>
              {renderResultsData()}
            </ul>

          </Results>
        }

        {showResults3 &&
            <Results results="true">
              <ResultsInner style={{textAlign: 'center', color: '#03c', textShadow: '0px 0px 4px rgba(255, 255, 255, 1)'}}>
              <h2 style={{fontSize: '24px'}}>What is Surveillance Capitalism?</h2>
              </ResultsInner>
              <ResultsInner>
              <p></p>
              <p>Surveillance Capitalism can be understood as the process of tracking people, digitally or physically, collecting their personal data, and using it to influence behaviour in ways that are commercially beneficial - usually to corporations or other large actors. So, while you may have &lsquo;nothing to hide&rsquo;, collectively all the tiny choices we make, when collected, become massively invasive and highly effective for predicting behaviour.</p>
<p>People often put stickers over the cameras on their digital devices, as we tend to have a better understanding of traditional image-based modes of surveillance. However, considering that the optical mouse is the most widely sold smart camera (Belbachir, 2010) and the increasing ubiquity of touchscreens, the act of &lsquo;watching&rsquo; is still an embedded, less obvious, part of more complex surveillance models.</p>
<p>It is well documented that big tech companies are able to&nbsp;<a href="https://time.com/5197255/facebook-cambridge-analytica-donald-trump-ads-data/">manipulate voting</a> (Ghosh and Scott, 2018),&nbsp;<a href="https://www.nytimes.com/2014/06/30/technology/facebook-tinkers-with-users-emotions-in-news-feed-experiment-stirring-outcry.html">run psychological experiments</a> (Goel, 2014) for increased profits and can&nbsp;<a href="https://www.newscientist.com/article/mg22229754-900-even-online-emotions-can-be-contagious/">deliberately influence peoples&rsquo; emotional state</a> (Rutkin, 2014). This is especially dangerous as the more that software systems work to influence people in automated ways, the greater the chance that&nbsp;<a href="https://resources.infosecinstitute.com/big-data-discrimination/">people are affected differently</a> (&ldquo;Big Data Discrimination,&rdquo; 2017), depending on race, religion, class, gender, educational attainment, economic status, and beyond.</p>
<p>Surveillance Capitalism is often conducted in a way that is effectively lawless.&nbsp;<a href="https://www.theguardian.com/technology/2019/jan/20/shoshana-zuboff-age-of-surveillance-capitalism-google-facebook">As one example</a>, Google decided to photograph every street and house on the planet without asking anyone&rsquo;s permission, in accordance with the mantra that &ldquo;it is easier to ask for forgiveness than for permission&rdquo; (Naughton, 2019). With this in mind, the priorities of Surveillance Capitalism can be said to directly oppose equality and democracy.</p>
<p>There are many articles and research for you to learn more about Surveillance Capitalism, however, a good start is&nbsp;<a href="https://www.youtube.com/watch?v=hIXhnWUmMvw">interviews with Shoshana Zuboff</a> (<em>Shoshana Zuboff on surveillance capitalism | VPRO Documentary</em>, 2019), author of The Age of Surveillance Capitalism (Zuboff, 2019).</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><b>Bibliography</b></p>
<p>Belbachir, A.N. (Ed.), 2010. Smart cameras. Springer, London ; New York.</p>
<p>Big Data Discrimination [WWW Document], 2017. . Infosec Resour. URL https://resources.infosecinstitute.com/big-data-discrimination/ (accessed 8.12.20).</p>
<p>Ghosh, D., Scott, B., 2018. Facebook&rsquo;s New Controversy Shows How Easily Online Political Ads Can Manipulate You [WWW Document]. Time. URL https://time.com/5197255/facebook-cambridge-analytica-donald-trump-ads-data/ (accessed 8.12.20).</p>
<p>Goel, V., 2014. Facebook Tinkers With Users&rsquo; Emotions in News Feed Experiment, Stirring Outcry. N. Y. Times.</p>
<p>Naughton, J., 2019. &ldquo;The goal is to automate us&rdquo;: welcome to the age of surveillance capitalism. The Observer.</p>
<p>Rutkin, A., 2014. Even online, emotions can be contagious [WWW Document]. New Sci. URL https://www.newscientist.com/article/mg22229754-900-even-online-emotions-can-be-contagious/ (accessed 8.12.20).</p>
<p>Shoshana Zuboff on surveillance capitalism | VPRO Documentary, 2019.</p>
<p>Surveillance capitalism [WWW Document], 2020. . Wikipedia. URL https://en.wikipedia.org/w/index.php?title=Surveillance_capitalism&amp;oldid=972430646 (accessed 8.12.20).</p>
<p>Zuboff, S., 2019. The age of surveillance capitalism: the fight for a human future at the new frontier of power, First edition. ed. PublicAffairs, New York.</p>
</ResultsInner>


            </Results>
          }

      <Consent />
      </Background>
      {showResults & !showResults2 &&
        <>
          <div style={{width: '100%', position: 'fixed', bottom: 0, height: '9rem', background: 'rgba(170,170,170,.5)', backdropFilter: 'blur(8px)',zIndex: '999'}}>
            <DataBtn onClick={() => {setShowResults2(true);}}>
              SEE DATA
            </DataBtn>
          </div>
        </>}
      {showResults2 & !showResults3 &&
        <>
          <DownloadBtn onClick={test}>
            DOWNLOAD
          </DownloadBtn>
          <SCBtn onClick={() => {setShowResults3(true);}}>
            FURTHER INFO
          </SCBtn>
          <Button onClick={restart}>
            RESTART QUIZ
          </Button>
        </>}
        {showResults3 &&
          <>
          <div style={{width: '100%', position: 'fixed', bottom: 0, height: '9rem', background: 'rgba(170,170,170,.5)', backdropFilter: 'blur(8px)',zIndex: '999'}}>
            <DataBtn onClick={() => {setShowResults3(false);}}>
              SEE DATA
            </DataBtn>
          </div>
          </>}
      </>
    );

}

export default App;

const MuteBtn = styled(ImageFadeIn)`
  width: 30px;
  height: 30px;
  position: fixed;
  top: 2rem;
  left: calc(50% - 15px);
  z-index: 99;
  cursor: pointer;
  opacity: ${props => props.toggle === false ? '1' : '.3!important'};
  &:hover {
    opacity: .3!important;
  }
`;

const Background = styled.div`
  background: transparent;
  height: 100%;
  width: 100%;
  touch-action: none;
`;

const InnerBg = styled.div`
  background:
    ${props => props.currentQuestion > 3 ? '#fff'
    : props.currentQuestion > 2 ? '#3a60d4'
    : props.currentQuestion > 1 ? '#1d48ca'
    : '#fff'};
  position: fixed;
  top: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: -2;
  opacity: ${props => props.currentAnswer ? '.6'
           : props.currentQuestion > 0 ? '1' : '0'};
  transition: opacity 3s linear,
              background 2s ease-in-out
              ;

  > div#tsparticles {
    height: 100%;
    overflow: hidden;

  }
`;

const InnerFakeBg = styled.div`
  position: absolute;
  top: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: -1;
  background:
    radial-gradient(at
      ${props => props.mouseYp}%
      ${props => props.mouseXp}%,
      rgb(
        ${
          props => (props.clickCount * 3) > 255 ? 255
          : (props.clickCount * 3) < 10 ? 10
          : (props.clickCount * 3)
        }
        ,152,219),
      rgb(
        155,
        ${
          props => (props.clickCount * 2) > 120 ? 120
          : (props.clickCount * 2) < 60 ? 60
          : (props.clickCount * 2)
        },
        182)
    );
  opacity:
    ${
      props => props.currentQuestion > 5 ? '.75'
      : props.currentQuestion > 4 ? '1'
      : props.currentQuestion > 3 ? '.3' : '0'
    }
  ;
  transition: opacity 3s ease-in-out;
`;

const Container = styled.div`
  background-color: transparent;
  min-height: 100%;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2rem;
  color: #000;
  text-align: center;
  z-index: 10;

  ${props => props.results === "true" && `
    span.correct {
      color: #c8ffbb;
    }

    span.failed {
      color: #f27c7c;
    }
    `
  }
`;

const Inner = styled.div`
  background: transparent;
  height: ${props => props.currentQuestion === 8 ? "70%" : "80%"};
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  top: ${props => props.currentQuestion === 8 ? "20%" : "10%"};

  @media (min-width: 700px) {
    height: calc(17vmax + 200px);
    top: 5%;
    align-items: center;
    @media (min-height: 500px) {
      top: 40%;
    }
  }

`;

const CanvasDiv = styled.div`
  background: ${props => props.currentQuestion === 18 ? "rgba(255,255,255,.8)" : "rgba(255,255,255,0)"}
  transition: background 2s ease-in-out;
`;

const StyledQuestion = styled(Question)`
  > h1 {
    color:
      ${props => props.currentAnswer ? '#cc004b'
      : props.currentQuestion > 3 ? '#03c'
      : props.currentQuestion > 1 ? '#eee'
      : '#03c'};
      transition: color 3s ease-in-out;
  }
  background: transparent;
  height: auto;
  width: 95vmin;
  padding: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  top: 0;
`;

const PrevAnswer = styled.div`
  position: absolute;
  top: 10%;
  color: red;
  font-size: 3rem;
  padding-right: 20px;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 1);
  @media (min-width: 700px) {
    top: 35%;
  }
`;

const Error = styled.div`
  position: absolute;
  bottom: 40vh;
  color: #c30;
  transition: ease-in-out, all 1s ease-in-out;
  @media (min-width: 700px) {
    bottom: 60vh;
  }
`;

const Continue = styled.button`
  background: transparent;
  color: ${props => props.currentAnswer ? '#03c' : '#ccc'};
  position: fixed;
  top: 1rem;
  font-size: 3rem;
  right: 0;
  padding: 1rem 1.5rem;
  cursor: pointer;
  transition: ease-in-out, all 1s ease-in-out;
  z-index: 21;

  &:hover {
    color: #fff;
    background: #03c;
    transition: ease-in-out, all 1s ease-in-out;
    outline: none;
  }

  &:focus {
    outline: none;
    transition: ease-in-out, all 1s ease-in-out;
    background: transparent;
    color: #ccc;
  }
  &:active {
    outline: none;
    transition: ease-in-out, all 1s ease-in-out;
    background: transparent;
    color: #ccc;
  }
`;

const Back = styled.button`
  background: transparent;
  color: #c30;
  position: fixed;
  top: 1rem;
  font-size: 3rem;
  left: 0;
  padding: 1rem 1.5rem;
  cursor: pointer;
  transition: ease-in-out, all 1s ease-in-out;

  &:hover {
    color: #fff;
    background: #03c;
    transition: ease-in-out, all 1s ease-in-out;
    outline: none;
  }

  &:focus {
    background: transparent;
    color: #c30;
    outline: none;
  }
  &:active {
    background: transparent;
    color: #c30;
    outline: none;
  }
`;

const Results = styled.div`
  position: fixed;
  top: 0;
  padding-top: 7rem;
  overflow: hidden;
  height: 100%;
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  z-index: 98;
  font-size: 12px;
  background: rgba(0,0,0,.5);
  color: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 10rem;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
}

  h2 {
    font-size: 14px;
    font-weight: normal
  }
  > div {
    margin-bottom: .5rem;
  }
  > ul {
    margin-bottom: .5rem;
    margin: 0;
    padding: 0;
  }
  a {
    color: #d7dfff;
    &:hover {
      text-decoration: underline;
    }
  }

`;

const ResultsInner = styled.div`
  width: 100%;
  max-width: 720px;

  text-align: left;
  font-size: 16px;
  @media (min-width: 1220px) {
    margin: 0 auto;
  }
`;

const Button = styled.button`
  background: #c30;
  color: #fff;
  border: none;
  padding: .3rem;
  width: 40%;
  height: 5rem;
  bottom: 2rem;
  right: 2rem;
  border-radius: none;
  text-align: center;
  margin: 0 auto;
  position: fixed;
  font-size: 20px;
  transition: background .5s linear;
  z-index: 99;
  @media (min-width: 700px) {
    width: 20vmax;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    outline: none;
    background: #cc004b;
    cursor: pointer;
    transition: background .3s linear;
  }
`;

const DownloadBtn = styled.button`
  background: #1c2;
  color: #fff;
  border: none;
  padding: .3rem;
  width: 40%;
  height: 5rem;
  bottom: 20rem;
  right: 2rem;
  border-radius: none;
  text-align: center;
  margin: 0 auto;
  position: fixed;
  font-size: 20px;
  transition: background .5s linear;
  z-index: 99;
  @media (min-width: 700px) {
    width: 20vmax;
    bottom: 2rem;
    right: calc(40vmax + 6rem);
  }
  @media (min-width: 1000px) {
    bottom: 20rem;
    right: 2rem;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    outline: none;
    background: #008b0d;
    cursor: pointer;
    transition: background .3s linear;
  }
`;

const DataBtn = styled(DownloadBtn)`
  bottom: 2rem;
  right: 0;
  left: 0;
  @media (min-width: 700px) {
    bottom: 2rem;
    right: 0;
    left: 0;
  }
  @media (min-width: 1000px) {
    bottom: 2rem;
    right: 0;
    left: 0;
  }
`;
const SCBtn = styled.button`
  background: #ea5b11;
  color: #fff;
  border: none;
  padding: .3rem;
  width: 40%;
  height: 5rem;
  bottom: 12rem;
  right: 2rem;
  border-radius: none;
  text-align: center;
  margin: 0 auto;
  position: fixed;
  font-size: 20px;
  transition: background .5s linear;
  z-index: 99;
  @media (min-width: 700px) {
    width: 20vmax;
    bottom: 2rem;
    right: calc(20vmax + 4rem);
  }
  @media (min-width: 1000px) {
    bottom: 12rem;
    right: 2rem;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    outline: none;
    background: #cc004b;
    cursor: pointer;
    transition: background .3s linear;
  }
`;
