import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

interface CanvasProps {
    width: number;
    height: number;
    currentQuestion: number;
}

type Coordinate = {
    x: number;
    y: number;
};

const Canvas = ({ width, height, currentQuestion }: CanvasProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [isPainting, setIsPainting] = useState(false);
    //
    const [entryClicks, setEntryClicks] = useState(0);
    const [error, setError] = useState('');
    const renderError = () => {
      if(!error){
        return;
      }
      return <Error>{error}</Error>;
    };

    const drawLine = useCallback((originalMousePosition: Coordinate, newMousePosition: Coordinate) => {
        if (!canvasRef.current) {
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;
        const context = canvas.getContext('2d');
        if (context) {
            context.strokeStyle = "hsl(" + (570 - (new Date().getUTCHours() * 15) + (entryClicks * 11 )) + ", 74%, 46%)";
            context.lineJoin = 'round';
            context.lineWidth = 3;

            context.beginPath();
            context.moveTo(originalMousePosition.x, originalMousePosition.y);
            context.lineTo(newMousePosition.x, newMousePosition.y);
            context.closePath();

            context.stroke();
        }
    }, [entryClicks]);

    const drawDot = useCallback((originalMousePosition: Coordinate) => {
        if (!canvasRef.current) {
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;
        const context = canvas.getContext('2d');
        if (context) {
            context.strokeStyle = "hsl(" + (570 - (new Date().getUTCHours() * 15) + (entryClicks * 11 )) + ", 74%, 46%)";
            context.lineJoin = 'round';
            context.lineWidth = 3;

            context.beginPath();
            context.moveTo(originalMousePosition.x - .5, originalMousePosition.y -.5);
            context.lineTo(originalMousePosition.x - .5, originalMousePosition.y + .5);
            context.lineTo(originalMousePosition.x + .5, originalMousePosition.y + .5);
            context.lineTo(originalMousePosition.x + .5, originalMousePosition.y - .5);
            context.lineTo(originalMousePosition.x - .5, originalMousePosition.y - .5);
            context.closePath();

            context.stroke();
        }
    }, [entryClicks]);

    //
    const [mousePosition, setMousePosition] = useState<Coordinate | undefined>(undefined);

    const startPaint = useCallback((event: TouchEvent) => {
        event.preventDefault();
        const coordinates = getCoordinates(event);
        if (coordinates) {
            setMousePosition(coordinates);
            setIsPainting(true);
            drawDot(coordinates);
        }
    }, [drawDot]);

    const startPaintMouse = useCallback((event: MouseEvent) => {
        const coordinates = getCoordinatesMouse(event);
        if (coordinates) {
            setMousePosition(coordinates);
            setIsPainting(true);
            drawDot(coordinates);
        }
    }, [drawDot]);

    useEffect(() => {
        if (!canvasRef.current) {
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;
        canvas.addEventListener('mousedown', startPaintMouse, { passive: true });
        canvas.addEventListener('touchstart', startPaint, { passive: true });
        return () => {
            canvas.removeEventListener('mousedown', startPaintMouse);
            canvas.removeEventListener('touchstart', startPaint);
        };
    }, [startPaint, startPaintMouse]);

    useEffect(() => {
        if (currentQuestion !== 6 && currentQuestion !== 18) {
            setError('');
        }
    }, [currentQuestion]);

    const paint = useCallback(
        (event: TouchEvent) => {
            if (isPainting) {
                const newMousePosition = getCoordinates(event);
                if (mousePosition && newMousePosition) {
                    drawLine(mousePosition, newMousePosition);
                    setMousePosition(newMousePosition);
                }
            }
        },

        [isPainting, mousePosition, drawLine]
    );

    const paintMouse = useCallback(
        (event: MouseEvent) => {
            if (isPainting) {
                const newMousePosition = getCoordinatesMouse(event);
                if (mousePosition && newMousePosition) {
                    drawLine(mousePosition, newMousePosition);
                    setMousePosition(newMousePosition);
                }
            }
        },

        [isPainting, mousePosition, drawLine]
    );

    useEffect(() => {
        if (!canvasRef.current) {
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;

        canvas.addEventListener('touchmove', paint, { passive: true });
        canvas.addEventListener('mousemove', paintMouse, { passive: true });
        canvas.addEventListener('click', paintMouse, { passive: true });
        return () => {
            canvas.removeEventListener('touchmove', paint);
            canvas.removeEventListener('mousemove', paintMouse);
            canvas.removeEventListener('click', paintMouse);
        };
    }, [paint, paintMouse]);

    const exitPaint = useCallback(() => {
        setIsPainting(false);
        setMousePosition(undefined);
        if (currentQuestion === 6 && entryClicks < 6) {
          setError('Draw lines connecting 5 dots and then select an answer');
          if (entryClicks < 1) {
          alert('Try to draw lines connecting 5 dots and then select an answer');
          }
          setEntryClicks(entryClicks => entryClicks + 1);
        } else if (currentQuestion === 18 && entryClicks < 16) {
          setEntryClicks(entryClicks => entryClicks + 1);
          if (entryClicks > 5) {
            setError('Draw something from your surroundings');
            if (entryClicks < 7) {
              alert('Draw something from your surroundings');
            }
          }
        } else {
          setError('');
        }
    }, [entryClicks, currentQuestion]);

    useEffect(() => {
        if (!canvasRef.current) {
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;
        canvas.addEventListener('touchend', exitPaint);
        canvas.addEventListener('mouseup', exitPaint);
        canvas.addEventListener('mouseleave', exitPaint);
        return () => {
            canvas.removeEventListener('touchend', exitPaint);
            canvas.removeEventListener('mouseup', exitPaint);
            canvas.removeEventListener('mouseleave', exitPaint);
        };
    }, [exitPaint]);

    const getCoordinates = (event: TouchEvent): Coordinate | undefined => {
        if (!canvasRef.current) {
            return;
        }

        const canvas: HTMLCanvasElement = canvasRef.current;
        return { x: event.touches[0].pageX - canvas.offsetLeft, y: event.touches[0].pageY - canvas.offsetTop };
    };

    const getCoordinatesMouse = (event: MouseEvent): Coordinate | undefined => {
        if (!canvasRef.current) {
            return;
        }

        const canvas: HTMLCanvasElement = canvasRef.current;
        return { x: event.pageX - canvas.offsetLeft, y: event.pageY - canvas.offsetTop };
    };



    return (
      <>
      <StyledCanvas ref={canvasRef} height={height} width={width}
      style={{zIndex: entryClicks > 5 ? 0 : 20,
              background: (currentQuestion === 6 && entryClicks > 0 && entryClicks < 6) ? "rgba(255,255,255,.3)" : "rgba(255,255,255,0)",

            }} >

      </StyledCanvas>
      {currentQuestion > 17 &&
      <StyledCanvas ref={canvasRef} height={height} width={width}
      style={{zIndex: entryClicks > 15 ? 0 : 21,
              background: (currentQuestion === 18 && entryClicks > 6 && entryClicks < 16) ? "rgba(255,255,255,.7)" : (currentQuestion === 18 && entryClicks > 15) ? "rgba(255,255,255,.3)" : "rgba(255,255,255,0)",

            }} >

      </StyledCanvas>
      }
      {renderError()}
      </>
    );
};

Canvas.defaultProps = {
    width: window.innerWidth,
    height: window.innerHeight,
    entryClicks: 0,
    currentQuestion: 0,
};

export default Canvas;

const StyledCanvas = styled.canvas`
  position: fixed;
  top: 0;
`;

const Error = styled.div`
  position: fixed;
  top: 85vh;
  color: #c30;
  transition: ease-in-out, all 1s ease-in-out;
`;
