import React, { Component, useState } from "react";
import styled from '@emotion/styled';

function BlurCorner(props) {
  const [corner, setCorner] = useState(0);

  return (
    <BlurDiv corner={props.corner}/>
  );
}

export default BlurCorner;

const BlurDiv = styled.div`
  position: fixed;
  top: ${props => props.corner < 2 ? '0' : '50%'};
  left: ${props => props.corner < 1 ? '0' : props.corner < 2 ? '50%' : props.corner < 3 ? '0' : props.corner < 4 ? '50%' : '0'};
  height: 50%;
  width: 50%;
  z-index:99;
  display: block;
  border-radius: 50%;
  background: transparent;
  backdrop-filter: blur(4px);
  transition: top 1s ease-in-out, left 1s ease-in-out;
`;
