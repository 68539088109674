import React from 'react';
import Answer from './Answer';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/core';

import agreeS from '../media/Agree.mp3';
import disagreeS from '../media/Disagree.mp3';

const Answers = (props) => {

  //console.log(props.question.id);
  return (
    <>

      <StyledAnswerA
          letter="AGREE"
          className={props.className}
          answer={props.question.answer_a}
          handleClick={props.handleClick}
          selected={props.currentAnswer === props.question.answer_a}
          qNo={props.question.id}
          clickDiff={props.clickDiff}
      />
      <StyledAnswerB
          letter="DISAGREE"
          className={props.className}
          answer={props.question.answer_b}
          handleClick={props.handleClick}
          selected={props.currentAnswer === props.question.answer_b}
          qNo={props.question.id}
          clickDiff={props.clickDiff}
      />
    </>
  );
}

export default Answers;

const StyledAnswerA = styled(Answer)`
  background: ${props => props.qNo > 2 ? 'rgba(255, 255, 255, .15)' : 'rgba(180, 180, 180, .3)'};
  color: ${props => props.qNo > 4 ? '#03c' : props.qNo > 2 ? '#fff' : '#03c'};
  border: none;
  padding: 1rem .5rem;
  width: 45vw;
  height: ${props => props.qNo > 2 ? '45vw' : '5rem'};

  border-radius: ${props => props.qNo > 2 && '50%'};
  text-align: center;
  margin: 1rem ${props => props.qNo > 2 ? '1vw' : '1px'} 0;
  position: relative;
  font-size: 20px;
  cursor: pointer;
  @media (min-width: 330px) {
    width: 17vmax;
    height: ${props => props.qNo > 2 ? '17vmax' : '5rem'};
    max-width: 180px;
    max-height: 180px;
  }
  transition:
    color .5s ease-in-out,
    background-color .5s ease-in-out,
    height 1s ease-in-out,
    border-radius 3s ease-in-out
  ;

  color: ${props => props.selected && '#fff'};
  background-color: ${props => props.selected && '#03c'};
  opacity: ${props => (props.qNo === 7 && props.clickDiff < 6) ? .2 : 1};

  ${props => props.qNo === 11 ? css`animation: ${bounceY2} 2s linear infinite alternate;`
    : props.qNo === 10 && css`animation: ${bounceX} 3s linear infinite alternate;`
  }
  @media (pointer: fine) {
    &:hover {
      color: #fff;
      background-color: #03c;
      transition: color .3s ease-in-out, background-color .3s ease-in-out;
    }
  }

  &:focus {
    outline: none;
    transition: color .3s ease-in-out, background-color .3s ease-in-out;
  }
`;

const StyledAnswerB = styled(Answer)`
  ${props => props.qNo === 19 && "display: none;"}
  background: ${props => props.qNo > 2 ? 'rgba(255, 255, 255, .15)' : 'rgba(180, 180, 180, .3)'};
  color: ${props => props.qNo > 4 ? '#03c' : props.qNo > 2 ? '#fff' : '#03c'};
  border: none;
  padding: 1rem .5rem;
  width: 45vw;
  height: ${props => props.qNo > 2 ? '45vw' : '5rem'};
  border-radius: ${props => props.qNo > 2 && '50%'};
  text-align: center;
  margin: 1rem ${props => props.qNo > 2 ? '1vw' : '1px'} 0;
  position: relative;
  font-size: 20px;
  cursor: pointer;

  @media (min-width: 330px) {
    width: 17vmax;
    height: ${props => props.qNo > 2 ? '17vmax' : '5rem'};
    max-width: 180px;
    max-height: 180px;
  }
  transition:
    color .5s ease-in-out,
    background-color .5s ease-in-out,
    height 1s ease-in-out,
    border-radius 3s ease-in-out
  ;

  color: ${props => props.selected && '#fff'};
  background-color: ${props => props.selected && '#03c'};
  opacity: ${props => (props.qNo === 7 && props.clickDiff < 6) ? .2 : 1};

  ${props => props.qNo === 11 ? css`animation: ${bounceY} 2s linear infinite alternate;`
    : props.qNo === 10 && css`animation: ${bounceX2} 2s linear infinite alternate;`
  }
  @media (pointer: fine) {
    &:hover {
      color: #fff;
      background-color: #03c;
      transition: color .3s ease-in-out, background-color .3s ease-in-out;
    }
  }

  &:focus {
    outline: none;
    transition: color .3s ease-in-out, background-color .3s ease-in-out;
  }
`;

const bounceX = keyframes`
  100% {
    transform: translateX( calc(30vw - 20vmax) );
  }
`;

const bounceY = keyframes`
  100% {
    transform: translateY( calc(100vh - 20vmax) );
    transform: translateX( calc(70vw - 20vmax) );
  }
`;
const bounceX2 = keyframes`
  100% {
    transform: translateX( calc(-30vw + 20vmax) );
  }
`;

const bounceY2 = keyframes`
  100% {
    transform: translateY( calc(-100vh + 20vmax) );
    transform: translateX( calc(-80vw + 20vmax) );
  }
  0% {
    transform: translateY( calc(100vh - 20vmax) );
    transform: translateX( calc(70vw - 20vmax) );
  }
`;
