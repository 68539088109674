import React, { Component, useState, useRef } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import styled from '@emotion/styled';
import ImageFadeIn from "react-image-fade-in";
import logo from '../media/compiler-logo.svg';
import phoenixLogo from '../media/PHO_ID_CMYK_BLK_FixedX-01.svg';
import Privacy from './Privacy'

//import agreeS from '../media/Agree.mp3';

function Consent(props) {
  const [display, setDisplay] = useState(true);
  //const agreeRef = useRef(null);

  return (
    <Modal display={display}>

      <LogoDiv>
        <a href="https://compiler.zone" target="_blank">
          <Img src={logo} />
        </a>
        <span>@</span>
        <a href="https://www.phoenix.org.uk" target="_blank">
          <PhoenixLogo src={phoenixLogo} />
        </a>
      </LogoDiv>

      <Intro>
      <p>present</p>
      <h1>Are / You / Actually</h1>
      <p>A quiz on online idleness!</p>
      <p>Please accept the terms and conditions to start</p></Intro>
      <PrivacyDiv />
      <CookieConsent
        location="bottom"
        buttonText="ACCEPT"
        cookieName="seriousCookie"
        style={{ background: "#03c" }}
        buttonStyle={{ color: "#03c", fontSize: "14px", fontWeight: "bold" }}
        expires={150}
        debug={true}
        onAccept={() => {
          setDisplay(false);
          //agreeRef.current.play();
          //alert("hooray! ʕノ•ᴥ•ʔノ");
        }}
      >
        <StyledConsent>This website uses cookies for artistic purposes. </StyledConsent>{" "}
        <SmallPrint></SmallPrint>
      </CookieConsent>
    </Modal>
  );
}

export default Consent;

const StyledConsent = styled.span`
  font-size: 11px;
  @media (min-width: 700px) {
    font-size: 14px;
  }
`;
const SmallPrint = styled.span`
  font-size: 8px;
  @media (min-width: 700px) {
    font-size: 10px;
  }
`;
const Img = styled(ImageFadeIn)`
  width: 300px;
  max-width: 40vw;
  @media (min-width: 700px) {
    width: 300px;
  }
`;

const PhoenixLogo = styled(ImageFadeIn)`
  width: 150px;
  max-width: 20vw;
  @media (min-width: 700px) {
    width: 150px;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index:99;
  padding: 2rem 0 4rem;
  display: ${props => props.display ? 'flex' : 'none'};
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: rgba(255, 0, 106, 0.5);
  backdrop-filter: blur(6px);

`;

const PrivacyDiv = styled(Privacy)`
  --scrollbarBG: #03c;
  --thumbBG: #fff;
  height: 40%;
  width: calc(100% - 2rem);
  max-width: 720px;
  margin: 1rem auto 0;
  padding: 0 1rem;
  overflow: auto;
  font-size: 9px;
  background: rgba(255,255,255,.5);
  border: 2px solid var(--scrollbarBG);

  @media (min-width: 700px) {
    margin: 3rem auto 0;
  }
  &::-webkit-scrollbar {
    width: 11px;
  }
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border: 3px solid var(--scrollbarBG);
    border-left: 4px solid var(--scrollbarBG);
    border-right: 2px solid var(--scrollbarBG);
  }
`;
const Intro = styled.div`
  color: #03c;
  text-align: center;
  padding: 1rem 0 0 0;
  font-size: 14px;
  > h1 {
    margin: 0 0 2rem;
    font-size: 2rem;
    @media (min-width: 700px) {
      font-size: 3.6rem;
    }
  }
  @media (min-width: 700px) {
    padding: 3rem 0 0 0;
    font-size: 18px;
  }
`;
const LogoDiv = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  align-items: center;
  justify-content: center;
  > span {
    margin: 0 4rem;
    color: #03c;
  }
  @media (min-width: 700px) {

  }
`;
