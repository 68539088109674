import React from 'react';
import styled from '@emotion/styled';

function CmpLines(props) {

  return (
    <>
    <Lines>
      {Array.from(Array(props.current), (e, i) => {

        return (
          <Line
            key={i}
            qNo={props.current}
            startSecs={props.startSecs}
            seconds={props.seconds}
            timex={(props.times[i] - props.times[0]) / (props.times[props.current - 1] - props.times[0]) * 100}
          />
        );
      })}
      </Lines>
      <Lines>
      {Array.from(Array(props.answersArr.length), (e, i) => {
        return (
          <Vline
            key={i}
            qNo={props.current}
            agreeDisagree={
              (props.answersArr[i].answer === "AGREE" || props.answersArr[i].answer === "YES") ?
              (i / (props.answersArr.length) * 50)
              : (props.answersArr[i].answer === "DISAGREE" || props.answersArr[i].answer === "NO") && 50 + (i / (props.answersArr.length) * 50)
            }
            color={
              props.answersArr[i].answer === "DISAGREE" ?
              "#03c" : "#c30"
            }
          />
        );
      })}
    </Lines>
    </>
  );
}

export default CmpLines;

const Lines = styled.div`
  background: transparent;
  height: 100%;
  width: 100%;
`;

const Line = styled.div`
  background: ${props => props.qNo > 4 ? '#cc004b' : props.qNo > 2 ? '#fff' : '#cc004b'};
  height: 1px;
  width: 100%;
  position: absolute;
  transition: top .5s ease-in-out;
  opacity:  .3;
  top: ${props => (props.timex)}%;
`;

const Vline = styled.div`
  background: ${props => props.qNo > 4 ? (props.color) : props.qNo > 2 ? '#fff' : (props.color)};
  height: 100%;
  width: 1px;
  position: absolute;
  transition: top .5s ease-in-out;
  opacity: .3;
  top: 0;
  left: ${props => (props.agreeDisagree)}%;
`;
